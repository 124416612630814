import React, { useState } from "react"

import FormField from "../components/form/form-field/form-field"
import Select from "../components/form/select/select"
import OverviewGrid from "../components/overview-grid/overview-grid"
import FileDownloads from "../components/resources/file-downloads/file-downloads"
import SEO from "../components/seo"
import Tile from "../components/tile/tile"
import TileContentSpacer from "../components/tile/tile-content-spacer/tile-content-spacer"
import SideNavView from "../components/views/side-nav-view/side-nav-view"
import useDownloads from "../hooks/useDownloads"
import { useTranslation } from "../i18n/translate"

const ProductBrochures = ({ pageContext, location }) => {
  const { t } = useTranslation()
  const { type, title, description } = pageContext
  const downloads = useDownloads().filter((d) => d.type === type)

  const markets = []
  const groupedProductGroup = {}
  const availability_regions = []

  downloads.forEach((dl) => {
    // add "Others market"
    if (!dl.markets || dl.markets.length === 0) {
      dl.markets = [
        {
          name: { key: "DOWNLOADS-MARKET-OTHERS" },
        },
      ]
    }
    if (dl.markets) {
      dl.markets.forEach((market) => {
        if (!markets.includes(market.name.key)) markets.push(market.name.key)
      })
    }
    if (dl.product_groups) {
      dl.product_groups.forEach((p) => {
        const groupKey = dl.group
        if (!groupedProductGroup[groupKey]) {
          groupedProductGroup[groupKey] = []
        }
        if (
          groupedProductGroup[groupKey].findIndex(
            (group) => group.translationKey === p.value.key,
          ) === -1
        ) {
          groupedProductGroup[groupKey].push({
            translationKey: p.value.key,
            groupKey,
          })
        }
      })
    }

    if (dl.availability_regions) {
      dl.availability_regions.forEach((r) => {
        if (!availability_regions.includes(r.name.key))
          availability_regions.push(r.name.key)
      })
    }
  })

  const groups = []
  const productGroups = []

  let productGroupsIndex = 0
  for (const groupedProductGroupKey of Object.keys(groupedProductGroup).sort(
    (a, b) => t(a).localeCompare(t(b)),
  )) {
    const values = groupedProductGroup[groupedProductGroupKey].sort((a, b) =>
      t(a.translationKey).localeCompare(t(b.translationKey)),
    )

    groups.push({
      label: t(groupedProductGroupKey),
      from: productGroupsIndex,
      to: productGroupsIndex + values.length,
    })

    productGroups.push(...values)
    productGroupsIndex = productGroupsIndex + values.length
  }

  productGroups.map((group, index) => {
    group.key = `key_${index}`
  })

  const [market, setMarket] = useState()
  const [productGroupIndex, setProductGroupIndex] = useState(-1)
  const [availabilityRegion, setAvailabilityRegion] = useState()

  const filteredDownloads = downloads
    .filter((d) => {
      return (
        d.hasMarket(market) &&
        d.hasAvailabilityRegion(availabilityRegion) &&
        (productGroupIndex === -1 ||
          d.hasProductGroup(productGroups[productGroupIndex].translationKey)) &&
        (productGroupIndex === -1 ||
          d.group === productGroups[productGroupIndex].groupKey)
      )
    })
    .map((dl) => {
      const copy = Object.assign({}, dl)
      if (availabilityRegion) {
        copy.availability_regions = [...dl.availability_regions].filter(
          (region) => region.name.key === availabilityRegion,
        )
      }
      return copy
    })

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t(title)}
        descriptionTranslatedString={t(description)}
      />
      <SideNavView
        titleTranslatedString={t(title)}
        pathName={location.pathname}
      >
        <Tile tileSize={"s"} titleTranslatedString={t(title)}>
          <div className={"_fp-select-grid"}>
            {markets.length > 0 && type === "PRODUCT_BROCHURE" && (
              <FormField titleTranslatedString={t("MARKETS-MARKET")}>
                <Select
                  defaultText={t("MARKETS-ALL_MARKETS")}
                  darkAppearance
                  values={markets.sort((a, b) => t(a).localeCompare(t(b)))}
                  value={market}
                  onSelect={setMarket}
                  mapValueToString={(val) => t(val)}
                />
              </FormField>
            )}
            {productGroups.length > 0 && type === "TECHNICAL_INFORMATION" && (
              <FormField titleTranslatedString={t("PRODUCTS-PRODUCT_GROUP")}>
                <Select
                  defaultText={t("PRODUCTS-ALL_PRODUCT_GROUPS")}
                  darkAppearance
                  values={productGroups}
                  value={productGroups[productGroupIndex]}
                  groups={groups}
                  onSelect={(productGroup) => {
                    const gi = productGroups.findIndex(
                      (g) => g === productGroup,
                    )
                    setProductGroupIndex(gi)
                  }}
                  mapValueToString={(val) => t(val?.translationKey)}
                  mapValueToKey={(val) => val?.key}
                />
              </FormField>
            )}
            {availability_regions.length > 0 && (
              <FormField titleTranslatedString={t("GLOBAL-REGION")}>
                <Select
                  defaultText={t("GLOBAL-ALL_REGIONS")}
                  darkAppearance
                  values={availability_regions.sort((a, b) =>
                    t(a).localeCompare(t(b)),
                  )}
                  value={availabilityRegion}
                  onSelect={setAvailabilityRegion}
                  mapValueToString={(val) => t(val)}
                />
              </FormField>
            )}
          </div>

          <TileContentSpacer />
          <FileDownloads fullWidth={true} downloads={filteredDownloads} />
        </Tile>
      </SideNavView>
    </>
  )
}

export default ProductBrochures
